import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { HelpersService } from '../helpers/helpers.service';
import { bonusDesc } from '../../interfaces/interfaces';
import { UserCurrencyPipePipe } from '../../pipes/userCurrencyPipe/user-currency-pipe.pipe';

@Injectable({
  providedIn: 'root',
})
export class BonusesService {
  bonus$: EventEmitter<any> = new EventEmitter<any>();
  bonusRegistration$: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private helpers: HelpersService
  ) {}

  getBonuses() {
    // if (lang == '') {
    //   lang = this.helpers.getCookie('userlang');
    // }
    return this.http.post<[]>(`/api/v2/bonus/bonus/get`, {});
  }

  getPromoCodeBonus(promocode: string) {
    return this.http.get(`/bonuses?PromoCode=${promocode}`);
  }

  filterPromoBonuses(bonuses: any) {
    return bonuses.filter((elem: any) => elem.PromoCode !== 1);
  }

  filterBonuses(bonuses: any) {
    bonuses = bonuses.map((elem: any) => ({
      ...elem,
      Name: this.setBonusName(elem),
      readMore: false,
      bonusDesc: this.setBonusDesc(elem),
    }));
    return bonuses;
  }

  filterByType(bonuses: any[], type: string) {
    if (type === 'all') {
      return this.filterBonuses(bonuses);
    }

    return this.filterBonuses(
      bonuses.filter((elem: any) => {
        return (
          elem.event === type || (type === 'deposit' && (elem.event === 'FIRST_DEPOSIT' || elem.event === 'DEPOSIT'))
        );
      })
    );
  }

  setBonusName(bonus: any) {
    const language = this.helpers.getCookie('userlang');
    return bonus.translations.find((t: any) => t.language === language)?.name || bonus.translations[0]?.name || null;
  }

  setBonusDesc(bonuses: any) {
    // const userCurrency = this.localStorage.getSessionStorage('currency') || 'USD';
    const result: Array<bonusDesc> = [];
    if (bonuses?.award[0]?.amount_limit) {
      result.push(
        this.setDescLine(
          'min_deposit',
          'https://cdn.chipychapa.com//welle/General_site/Bonuses/min_deposit.svg',
          bonuses?.award[0]?.amount_limit
        )
      );
    }
    if (bonuses?.award[0]?.wager?.wager_multiplier) {
      result.push({
        name: 'wager',
        img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/wager.svg',
        subtext: `x${bonuses?.award[0]?.wager?.wager_multiplier}`,
      });
    }
    if (bonuses?.award[0]?.amount) {
      result.push({
        name: 'bonus_amount',
        img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/game.svg',
        subtext: `${bonuses?.award[0]?.amount}`,
      });
    }
    //  if (desc.indexOf('fsgame=') > -1) {
    //   result.push({
    //     name: 'game',
    //     img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/game.svg',
    //     subtext: desc.split('=')[1],
    //   });
    // }
    // if (bonuses?.Description?.includes('FSbonus') && bonuses?.Results?.freerounds?.Value) {
    //   result.push({
    //     name: 'spins',
    //     img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/fs_icon.svg',
    //     subtext: bonuses?.Results?.freerounds?.Value,
    //   });
    // }

    // if (bonuses?.Description?.includes('Turnover') && bonuses?.Active) {
    //   result.push({
    //     name: 'progress',
    //     img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/game.svg',
    //     subtext: `${(bonuses?.WageringTotal - bonuses?.WageringLeft).toFixed(2)} ${userCurrency} /
    //     ${bonuses?.WageringTotal} ${userCurrency}`,
    //   });
    // }

    // if (bonuses?.Results?.balance?.AwardWagering?.COEF || bonuses?.Results?.freerounds?.AwardWagering?.COEF) {
    //   const resultCOEF = bonuses?.Results?.balance?.AwardWagering?.COEF;
    //   const finalCOEF =
    //     resultCOEF === undefined || resultCOEF === '' ? bonuses?.Results?.freerounds?.AwardWagering?.COEF : resultCOEF;
    // } else if (bonuses?.Terms.includes('wager')) {
    //   const splitedBonusDesc = bonuses.Terms.split('=');
    //   result.push({
    //     name: 'wager',
    //     img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/wager.svg',
    //     subtext: splitedBonusDesc[3],
    //   });
    // }

    // result.push(
    //   this.setDescLine(
    //     'max_bet',
    //     'https://cdn.chipychapa.com//welle/General_site/Bonuses/max_bet.svg',
    //     bonuses?.MaxBet[userCurrency] === '' ? '∞' : bonuses?.MaxBet[userCurrency]
    //   )
    // );

    // if (bonuses?.Results?.balance?.LimitValue[userCurrency]) {
    //   result.push(
    //     this.setDescLine(
    //       'max_bonus',
    //       'https://cdn.chipychapa.com//welle/General_site/Bonuses/max_bonus.svg',
    //       bonuses?.Results?.balance?.LimitValue[userCurrency]
    //     )
    //   );
    // }

    // result.push(
    //   this.setDescLine(
    //     'expires',
    //     'https://cdn.chipychapa.com//welle/General_site/Bonuses/expires.svg',
    //     bonuses?.ExpireDays
    //   )
    // );
    return result;
  }

  setDescLine(name: string, img: string, subtext: string) {
    return {
      name,
      img,
      subtext,
    };
  }

  filterActiveBonuses(bonuses: any) {
    return bonuses.filter((elem: any) => elem?.Active === 1);
  }

  filterSubscriptionBonuses(bonuses: any) {
    return bonuses.filter((elem: any) => elem?.Selected !== 1 || (elem?.Selected === 1 && elem?.PromoCode === 1));
  }

  chunkAmount(elem: any, userCurrency: string) {
    let limit = 0;
    let result: number =
      elem?.Event.indexOf('deposit') > -1
        ? Number(elem?.Bonus) / 10
        : Math.floor(elem?.Results?.balance?.Value[userCurrency] / 10);

    if (elem?.Event.indexOf('deposit') > -1) {
      limit = elem?.Results?.balance?.LimitValue[userCurrency];

      if (result > Number(limit) / 10) {
        result = Number(limit) / 10;
      }
    }
    return result;
  }
  leftToGet(elem: any, userCurrency: string) {
    let limit = 0;
    let result: number =
      elem?.Event.indexOf('deposit') > -1
        ? Number(elem?.Bonus) -
          (Number(elem?.Bonus) / 10) * Math.floor(((elem.WageringTotal - elem.WageringLeft) / elem.WageringTotal) * 10)
        : Math.floor(elem?.Results?.balance?.Value[userCurrency]) -
          Math.floor(
            (elem?.Results?.balance?.Value[userCurrency] / 10) *
              Math.floor(((elem.WageringTotal - elem.WageringLeft) / elem.WageringTotal) * 10)
          );
    if (elem?.Event.indexOf('deposit') > -1) {
      limit = elem?.Results?.balance?.LimitValue[userCurrency];
      if (result > Number(limit)) {
        result = Number(limit);
      }
    }
    return result;
  }

  getCurrentBonusBalance(bonusState: any) {
    if (bonusState?.length === 0 || bonusState === undefined) return;
    bonusState = bonusState?.filter((el: any) => el.Active == 1);
    if (
      Object.values(bonusState).length == 1 &&
      Number(bonusState[0].BonusBalance) <= 0 &&
      bonusState[0].Event.toLowerCase() === 'store'
    ) {
      this.cancelBonusId(bonusState[0].ID).subscribe(() => null);
      return;
    }
  }

  cancelBonusId(id: number) {
    return this.http.delete(`/bonuses/${id}`);
  }
  findBonusById(bonuses: any, id: string) {
    return bonuses.find((elem: any) => elem.id === id);
  }

  subscribeToBonus(id: string, code: string) {
    return this.http.post(`/bonuses/${id}`, { Selected: 1, PromoCode: code });
  }

  unsubscribeToBonus(id: string) {
    return this.http.post(`/bonuses/${id}`, {
      Selected: 0,
    });
  }

  sendBonus(bonus: any) {
    this.bonus$.emit(bonus);
  }
  sendBonusRegistration(bonus: any) {
    this.bonusRegistration$.emit(bonus);
  }
  checkIfBonusIsVisible(bonuses: any[]): any[] {
    return bonuses.map((bonus) => {
      try {
        const description = bonus?.Description ? JSON.parse(bonus?.Description) : {};

        const isVisible = description?.isVisible === 'true' || description?.isVisible === undefined;

        return {
          ...bonus,
          isVisible: isVisible,
        };
      } catch (error) {
        console.error('Error parsing Description:', error);
        return {
          ...bonus,
          isVisible: true,
        };
      }
    });
  }
  checkIfBonusIsWelcome(bonus: string): boolean {
    const bonusIds = [
      '131750',
      '120614',
      '118178',
      '133874',
      '133871',
      '134189',
      '159446',
      '160982',
      '160430',
      '160418',
      '159431',
      '159299',
      '159176',
      '139616',
      '157187',
      '162854',
      '162416',
    ];
    return !bonusIds.some((bonusId) => bonus.includes(bonusId));
  }

  getCashbackBonus(balance: number, currency: string = 'USD', amount: number) {
    const cashbackTiers = [
      { min: 2500000, name: 'cashback.poseidon', desc: 'cashback.15_percent', img: 'cashback_Poseidon.png' },
      { min: 500000, name: 'cashback.triton', desc: 'cashback.13_percent', img: 'cashback_Triton.png' },
      { min: 200000, name: 'cashback.admiral', desc: 'cashback.11_percent', img: 'cashback_Admiral.png' },
      { min: 50000, name: 'cashback.captain', desc: 'cashback.10_percent', img: 'cashback_Captain.png' },
      { min: 5000, name: 'cashback.boatswain', desc: 'cashback.9_percent', img: 'cashback_Boatswain.png' },
      { min: 0, name: 'cashback.sailor', desc: 'cashback.8_percent', img: 'cashback_Sailor.png' },
    ];

    const tier = cashbackTiers.find((t) => balance >= t.min) || cashbackTiers[cashbackTiers.length - 1];

    return {
      Name: tier.name,
      cashbackDesc: tier.desc,
      Image: `https://cdn.chipychapa.com//welle/General_site/Sidebar/LOYALTY/cashback/${tier.img}`,
      bonusDesc: [
        { name: 'bonus.wager', img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/wager.svg', subtext: 'x5' },
        {
          name: 'bonus.withdrawal_limit',
          img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/withdrawal_limit.svg',
          subtext: 'x10 ',
        },
        {
          name: 'bonus.amount',
          img: 'https://cdn.chipychapa.com//welle/General_site/Bonuses/cashback1.svg',
          subtext: `${new UserCurrencyPipePipe().transform(currency) + amount.toFixed(2)}`,
        },
      ],
    };
  }
}
